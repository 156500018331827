import { makeStyles } from '@material-ui/core/styles';
export const DashboardStyles = makeStyles(theme => ({
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100%',
		minHeight: '100vh',
		overflow: 'auto',
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	fixedHeight: {
		height: 240,
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},

	depositContext: {
		flex: 1,
	},

	detailTitle: {
		color: theme.palette.text.secondary,
		fontSize: 12,
	},

	detailValue: {
		marginBottom: 16,
		fontSize: 13,
	},
	lastLoginMsg: {
		textAlign: 'right',
		marginTop: -16,
	},
}));
export default DashboardStyles;
